/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 10px; /* ширина для вертикального скролла */
    height: 8px; /* высота для горизонтального скролла */
    background-color: #abb5bd;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    background-color: #768ba2;
    border-radius: 5em;
    box-shadow: inset 1px 1px 10px #f3faf7;
}
